import {SettingsParamType, createSettingsParams} from '@wix/tpa-settings'
import {EVENT_FILTER_TYPE, EVENT_SORT_ORDER} from '@wix/wix-events-commons-statics'
import {Override} from '../../commons/types/utils'

export interface ISettingsTexts {
  time: SettingsParamType.Text
  location: SettingsParamType.Text
  RSVPTitle: SettingsParamType.Text
  /**
   * @deprecated
   */
  registrationClosedButtonText: SettingsParamType.Text
  multiRsvpClosedBtnText: SettingsParamType.Text
  multiTicketedClosedBtnText: SettingsParamType.Text
  oneButton: SettingsParamType.Text
  /**
   * @deprecated
   */
  listButtonText: SettingsParamType.Text
  multiRsvpButtonText: SettingsParamType.Text
  multiTicketedButtonText: SettingsParamType.Text
  multiNoRegButtonText: SettingsParamType.Text
  todayButtonText: SettingsParamType.Text
  listGeneralTitle: SettingsParamType.Text
  soldOutRibbonText: SettingsParamType.Text
  joinWaitlistRibbonText: SettingsParamType.Text
  rsvpClosedRibbonText: SettingsParamType.Text
  membershipRibbonText: SettingsParamType.Text
  recurringRibbonText: SettingsParamType.Text
  moreInfo: SettingsParamType.Text
  lessInfo: SettingsParamType.Text
}

export interface ISettingsNumbers {
  filterType: SettingsParamType.Number
  sortOrder: SettingsParamType.Number
}

export interface ISettingsStrings {
  categoryId: SettingsParamType.String
}

export interface ISettingsParams extends ISettingsNumbers, ISettingsTexts, ISettingsStrings {}

export type WidgetComponentTextSettings = Override<ISettingsTexts, string>

const settingsParams = createSettingsParams<ISettingsParams>({
  time: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('whenLabel'),
  },
  location: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('whereLabel'),
  },
  RSVPTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('RSVPTitle'),
  },
  registrationClosedButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('registrationClosedButtonText'),
  },
  multiRsvpClosedBtnText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  multiTicketedClosedBtnText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  oneButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('oneButtonText'),
  },
  listButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  multiRsvpButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t, getSettingParamValue}) =>
      getSettingParamValue(settingsParams.listButtonText) || t('listRSVPButtonText'),
  },
  multiTicketedButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t, getSettingParamValue}) =>
      getSettingParamValue(settingsParams.listButtonText) || t('listTicketsButtonText'),
  },
  multiNoRegButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('viewer_listNoRegButtonText'),
  },
  todayButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('todayButtonText'),
  },
  listGeneralTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('listGeneralTitle'),
  },
  soldOutRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  joinWaitlistRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  rsvpClosedRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  membershipRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  recurringRibbonText: {
    type: SettingsParamType.Text,
    getDefaultValue: undefined,
  },
  moreInfo: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('moreInfoToggle'),
  },
  lessInfo: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('lessInfoToggle'),
  },
  filterType: {
    type: SettingsParamType.Number,
    getDefaultValue: () => EVENT_FILTER_TYPE.UPCOMING_AND_PAST,
  },
  categoryId: {
    type: SettingsParamType.String,
    getDefaultValue: undefined,
  },
  sortOrder: {
    type: SettingsParamType.Number,
    getDefaultValue: () => EVENT_SORT_ORDER.CHRONOLOGICAL,
  },
})

export default settingsParams
